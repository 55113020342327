.logo {
  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    &::after,
    &::before {
      content: ' ';
      position: absolute;
      width: 3rem;
      height: 3px;
      background-color: $line-color;
      opacity: 0;
      transition:
        opacity .25s,
        left 1.5s cubic-bezier(.15,.88,.94,1.14),
        right 1.5s cubic-bezier(.15,.88,.94,1.14);
    }
    &::before {
      top: 0;
      right: -1rem;
    }
    &::after {
      left: -1rem;
      bottom: 0;
    }
    &:hover::before,
    &:focus::before {
      opacity: 1;
      right: calc(50% - .5rem);
    }
    &:hover::after,
    &:focus::after {
      opacity: 1;
      left: calc(50% - .5rem);
    }
  }
}

.footer {
  .logo {
    a {
      &::after,
      &::before {
        background-color: $line-color;
      }
    }
  }
}
