.masthead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  transition:
    background-color $transition;
  &::before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #1a1a1a;
    transition:
      width $transition,
      height $transition;
  }
  // &.sticky {
  //   background-color: #333;
  // }
  &.sticky::before {
    height: 100%;
    // width: 100%;
  }
  h1,
  .logo {
    letter-spacing: $tracking;
  }
  @media only screen and (min-width: 38em) and (max-width: 750px) {
    font-size: .9090909091rem;
    h1 {
      font-size: 1.15rem;
      line-height: 1.3;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .menu-tog {
    outline: 0;
    // order: -1;
    width: 1.75rem;
    height: 19px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-end;
    @include min(60) {
      display: none;
    }
    span {
      width: 100%;
      height: 3px;
      background-color: #fff;
      position: relative;
      transition:
        transform $transition-quick,
        top $transition-quick,
        bottom $transition-quick;
    }
    .tog-top {
      top: 0;
    }
    .tog-bottom {
      // width: 66.6666%;
      bottom: 0;
    }
  }
  .nav {
    a {
      text-transform: uppercase;
      letter-spacing: .1em;
    }
    @include max(59.999) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding-top: 3.23625rem;
      background-color: #1a1a1a;
      transition: transform $transition;
      transform: translateY(-100%);
      z-index: -1;
      ul {
        // position: fixed;
        // top: 3.23625rem;
        // right: 0;
        padding: 2.6rem 1.3rem;
        text-align: center;
        li {
          &:not(:last-child) {
            margin-bottom: 1.3rem;
          }
          a {
            display: inline-block;
            font-size: 1.7926rem;
            text-decoration: none;
            opacity: 0;
            transform: translateY(50%);
          }
          &.has-dropdown {
            li {
              &:last-child {
                margin-bottom: 2.6rem;
              }
              a {
                font-size: 1.25rem;
                text-transform: none;
              }
            }
          }
        }
      }
    }
    @include min(60) {
      ul {
        display: flex;
        li {
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
        a {
          color: #fff;
          text-decoration: none;
          position: relative;
          &::after,
          &::before {
            content: ' ';
            position: absolute;
            width: 1.5rem;
            height: 2px;
            background-color: $line-color;
            opacity: 0;
            left: -.5rem;
            bottom: 0;
            transition:
              opacity .25s,
              left 1.5s cubic-bezier(.15,.88,.94,1.14),
              right 1.5s cubic-bezier(.15,.88,.94,1.14);
          }
          &:hover::after,
          &:focus::after {
            opacity: 1;
            left: calc(50% - .25rem);
          }
        }
      }
    }
  }
  &.open .nav {
    @include max(59.999) {
      transform: translateY(0);
    }
  }
}

@media only screen and (min-width: 60em) {

  .nav-focus-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    transform: translateY(-100%);
    background-color: #1a1a1a;
    opacity: .9;
    z-index: 9999;
    // display: none;
    transition:
      opacity $transition-quick,
      transform $transition-quick;
  }

  .dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    max-height: 0;
    width: 20em;
    overflow: hidden;
    transition:
      max-height $transition-quick;
    li:not(:last-child) {
      padding-bottom: .7rem
    }
    li {
      // white-space: nowrap;
      text-transform: uppercase;
    }
  }

  .nav-focused {
    a {
      opacity: .1;
    }
    .dropdown {
      max-height: 100vh;
    }
  }

  .has-dropdown {
    position: relative;
    a {
      opacity: 1;
    }
  }

}

@media only screen and (min-width: 60em) {

  .dropdown {
    li:not(:last-child) {
      margin-bottom: .75rem
    }
  }

}
