.img-banner,
.banner-bts {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 65vh;
  max-height: 32rem;
  display: flex;
  align-items: flex-end;
}

.vimeo-banner {
  position: relative;
  width: 100%;
  height: 100vh;
  // z-index: -1;
  overflow: hidden;
  @include bgBasic();
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  iframe {
    pointer-events: none;
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    // min-height: 120vh;
    // min-width: 213.33333333vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    min-height: 120vh;
    min-width: 213.3333333333vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // z-index: -2;
  }
  .vimeo-banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, .5);
    .vimeo-banner-title {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
      position: relative;
      &::after,
      &::before {
        content: ' ';
        position: absolute;
        width: 3rem;
        height: 3px;
        background-color: $line-color;
        // opacity: 0;
        transition:
          opacity .25s,
          left 1.5s cubic-bezier(.15,.88,.94,1.14),
          right 1.5s cubic-bezier(.15,.88,.94,1.14);
      }
      &::before {
        top: 0;
        right: -1rem;
        // animation: beforeRight 1.5s 1.5s cubic-bezier(.15,.88,.94,1) alternate infinite;
      }
      &::after {
        left: -1rem;
        bottom: 0;
        // animation: afterLeft 1.5s 1.5s cubic-bezier(.15,.88,.94,1) alternate infinite;
      }
    }
  }
  @media only screen and (max-width: 38em) {

    .vimeo-banner-title {
      &::after,
      &::before {
        display: none;
      }
    }

  }
  .scroller-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    .reel-link {
      @include hov() {
        opacity: .75;
      }
    }
  }
  a {
      text-decoration: none;
  }
  .scroller {
    height: 3rem;
    width: 3rem;
    position: relative;
    @include hov() {
      &::after {
        animation-play-state: paused;
      }
    }
    &::after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px;
      height: 50%;
      background-color: $line-color;
      transform: translate(-50%, -75%);
      animation: scrollShift 1.5s cubic-bezier(.75, 0, .25, 1) alternate infinite;
    }
  }
  .scroller-alt {
    padding: 0 2rem;
    .outer {
      height: 2.5rem;
      width: 3px;
      background-color: rgba(255, 255, 255, .5);
      margin: 0 auto;
      overflow: hidden;
      position: relative;
    }
    .inner {
      width: 100%;
      height: 50%;
      position: absolute;
      background-color: #fff;
      bottom: 100%;
      left: 0;
      animation: scrollInner 2s cubic-bezier(.75, 0, .25, 1) infinite;
    }
  }
  .banner-btn-orange-line {
    color: $line-color;
    &::after,
    &::before {
      background-color: #fff;
    }
  }
  .banner-btn-orange-bg {
    color: $line-color;
    background-color: transparent;
    padding: .25em 1em;
    &::after,
    &::before {
      display: none;
    }
    @include hov() {
      background-color: $line-color;
      color: #fff;
    }
  }
  .banner-btn-orange-outline {
    color: $line-color;
    border: 3px solid $line-color;
    padding: .25em 1em;
    display: inline-block;
    &::after,
    &::before {
      display: none;
    }
    @include hov() {
      background-color: $line-color;
      color: #fff;
    }
  }
}

@keyframes scrollShift {
  0% {
    transform: translate(-50%, -75%);
  }
  100% {
    transform: translate(-50%, -25%);
  }
}

@keyframes scrollInner {
  0% {
    // height: 50%;
    bottom: 100%;
  }
  100% {
    // height: 0;
    bottom: -50%;
  }
}

@keyframes beforeRight {
  0% {
    right: -1rem;
    opacity: 0;
  }

  100% {
    opacity: 1;
    right: calc(50% - .5rem);
  }
}

@keyframes afterLeft {
  0% {
    left: -1rem;
    opacity: 0;
  }

  100% {
    opacity: 1;
    left: calc(50% - .5rem);
  }
}
