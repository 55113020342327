// @import url('https://fonts.googleapis.com/css?family=Merriweather+Sans:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap');
@import 'utilities/variables';
@import 'utilities/mixins';
@import 'modules/modules';
@import 'modules/grid';
@import 'modules/type';
@import 'modules/colors';
@import 'styles/typography';
@import 'patterns/banners';
@import 'patterns/header';
@import 'patterns/footer';
@import 'patterns/logo';
@import 'patterns/video-player';
@import 'layout';

.vimeo-pop-up {
  border: 2px solid red;
}

html {
  scroll-behavior: smooth;
}
