.video-player-wrap {
  position: fixed;
  display: none;
  opacity: 0;
  transition:
    opacity $transition;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .85);
  z-index: 100000000;
  .video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95vw;
    height: 47.5vw;
    max-width: 1750px;
    max-height: 875px;
    // @include max(37.999) {
    //   width: 90vw;
    // }
    // @include min(38) {
    //   height: 90vh;
    // }
  }
}
