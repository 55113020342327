/*
  Gridifier || Code released under the UNLICENSE
  https://gridifier.web-dev.tools/#xs,4,0,0,0;s,4,25,0,0;m,4,38,1,1;l,6,60,1,1
*/

.grid {
  margin: 0;
  padding: 0;
  letter-spacing: -.31em;
  text-rendering: optimizeSpeed;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.grid-bottom {
  -webkit-align-items: flex-end;
  -ms-align-items: flex-end;
  align-items: flex-end;
}

.grid-middle {
  -webkit-align-items: center;
  -ms-align-items: center;
  align-items: center;
}

.grid-stretch {
  -webkit-align-items: stretch;
  -ms-align-items: stretch;
  align-items: stretch;
}

.opera-only :-o-prefocus,
.grid {
  word-spacing: -.43em;
}

.unit {
  letter-spacing: normal;
  text-rendering: auto;
  vertical-align: top;
  word-spacing: normal;
  display: inline-block;
  visibility: visible;
}

.grid-bottom .unit {
  vertical-align: bottom;
}

.grid-middle .unit {
  vertical-align: middle;
}

[class*="unit-push-"],
[class*="unit-pull-"] {
  position: relative;
}

.unit-content-distribute {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.unit-content-center,
.unit-content-center-vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-direction: column wrap;
  -ms-flex-direction: column wrap;
  flex-flow: column wrap;
}

.unit-content-center-horizontal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-direction: row wrap;
  -ms-flex-direction: row wrap;
  flex-flow: row wrap;
}

.content-fill,
.content-stretch {
  -ms-flex-grow: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  max-width: 100%; /* @bugfix: IE 10, 11 */
}

.content-shrink {
  -ms-align-self: center;
  -webkit-align-self: center;
  align-self: center;
}

.unit-xs-hidden,
.xs-0 {
  display: none;
  visibility: hidden;
}

.unit-xs-centered {
  margin: 0 auto;
}

.unit-xs-1,
.xs-1 {
  display: block;
  visibility: visible;
  width: 100%;
}

.unit-xs-1-2,
.xs-1-2 {
  width: 50%;
}

.unit-xs-1-3,
.xs-1-3 {
  width: 33.3333%;
}

.unit-xs-2-3,
.xs-2-3 {
  width: 66.6667%;
}

.unit-xs-1-4,
.xs-1-4 {
  width: 25%;
}

.unit-xs-3-4,
.xs-3-4 {
  width: 75%;
}

.unit-xs-1-2,
.xs-1-2,
.unit-xs-1-3,
.xs-1-3,
.unit-xs-2-3,
.xs-2-3,
.unit-xs-1-4,
.xs-1-4,
.unit-xs-3-4,
.xs-3-4 {
  display: inline-block;
  visibility: visible;
}

.unit-xs-1-2[class*="-content-"],
.xs-1-2[class*="-content-"],
.unit-xs-1-3[class*="-content-"],
.xs-1-3[class*="-content-"],
.unit-xs-2-3[class*="-content-"],
.xs-2-3[class*="-content-"],
.unit-xs-1-4[class*="-content-"],
.xs-1-4[class*="-content-"],
.unit-xs-3-4[class*="-content-"],
.xs-3-4[class*="-content-"] {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.unit-xs-content-distribute,
.xs-content-distribute {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.unit-xs-content-center,
.unit-xs-content-center-vertical {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-direction: column wrap;
  -ms-flex-direction: column wrap;
  flex-flow: column wrap;
}

.unit-xs-content-center-horizontal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-flex-direction: row wrap;
  -ms-flex-direction: row wrap;
  flex-flow: row wrap;
}

.xs-content-fill,
.xs-content-stretch {
  -ms-flex-grow: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  max-width: 100%; /* @bugfix: IE 10, 11 */
}

.xs-content-shrink {
  -ms-align-self: center;
  -webkit-align-self: center;
  align-self: center;
}

@media only screen and (min-width: 25em) {

  .unit-s-hidden,
  .s-0 {
    display: none;
    visibility: hidden;
  }

  .unit-s-centered {
    margin: 0 auto;
  }

  .unit-s-1,
  .s-1 {
    display: block;
    visibility: visible;
    width: 100%;
  }

  .unit-s-1-2,
  .s-1-2 {
    width: 50%;
  }

  .unit-s-1-3,
  .s-1-3 {
    width: 33.3333%;
  }

  .unit-s-2-3,
  .s-2-3 {
    width: 66.6667%;
  }

  .unit-s-1-4,
  .s-1-4 {
    width: 25%;
  }

  .unit-s-3-4,
  .s-3-4 {
    width: 75%;
  }

  .unit-s-1-2,
  .s-1-2,
  .unit-s-1-3,
  .s-1-3,
  .unit-s-2-3,
  .s-2-3,
  .unit-s-1-4,
  .s-1-4,
  .unit-s-3-4,
  .s-3-4 {
    display: inline-block;
    visibility: visible;
  }

  .unit-s-1-2[class*="-content-"],
  .s-1-2[class*="-content-"],
  .unit-s-1-3[class*="-content-"],
  .s-1-3[class*="-content-"],
  .unit-s-2-3[class*="-content-"],
  .s-2-3[class*="-content-"],
  .unit-s-1-4[class*="-content-"],
  .s-1-4[class*="-content-"],
  .unit-s-3-4[class*="-content-"],
  .s-3-4[class*="-content-"] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .unit-s-content-distribute,
  .s-content-distribute {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .unit-s-content-center,
  .unit-s-content-center-vertical {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: column wrap;
    -ms-flex-direction: column wrap;
    flex-flow: column wrap;
  }

  .unit-s-content-center-horizontal {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: row wrap;
    -ms-flex-direction: row wrap;
    flex-flow: row wrap;
  }

  .s-content-fill,
  .s-content-stretch {
    -ms-flex-grow: 2;
    -webkit-flex-grow: 2;
    flex-grow: 2;
    max-width: 100%; /* @bugfix: IE 10, 11 */
  }

  .s-content-shrink {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
  }

}

@media only screen and (min-width: 38em) {

  .unit-m-hidden,
  .m-0 {
    display: none;
    visibility: hidden;
  }

  .unit-m-centered {
    margin: 0 auto;
  }

  .unit-m-1,
  .m-1 {
    display: block;
    visibility: visible;
    width: 100%;
  }

  .unit-offset-m-0 {
    margin-left: 0;
  }

  .unit-push-m-0,
  .unit-pull-m-0 {
    left: 0;
  }

  .unit-m-1-2,
  .m-1-2 {
    width: 50%;
  }

  .unit-offset-m-1-2 {
    margin-left: 50%;
  }

  .unit-push-m-1-2 {
    left: 50%;
  }

  .unit-pull-m-1-2 {
    left: -50%;
  }

  .unit-m-1-3,
  .m-1-3 {
    width: 33.3333%;
  }

  .unit-offset-m-1-3 {
    margin-left: 33.3333%;
  }

  .unit-push-m-1-3 {
    left: 33.3333%;
  }

  .unit-pull-m-1-3 {
    left: -33.3333%;
  }

  .unit-m-2-3,
  .m-2-3 {
    width: 66.6667%;
  }

  .unit-offset-m-2-3 {
    margin-left: 66.6667%;
  }

  .unit-push-m-2-3 {
    left: 66.6667%;
  }

  .unit-pull-m-2-3 {
    left: -66.6667%;
  }

  .unit-m-1-4,
  .m-1-4 {
    width: 25%;
  }

  .unit-offset-m-1-4 {
    margin-left: 25%;
  }

  .unit-push-m-1-4 {
    left: 25%;
  }

  .unit-pull-m-1-4 {
    left: -25%;
  }

  .unit-m-3-4,
  .m-3-4 {
    width: 75%;
  }

  .unit-offset-m-3-4 {
    margin-left: 75%;
  }

  .unit-push-m-3-4 {
    left: 75%;
  }

  .unit-pull-m-3-4 {
    left: -75%;
  }

  .unit-m-1-2,
  .m-1-2,
  .unit-m-1-3,
  .m-1-3,
  .unit-m-2-3,
  .m-2-3,
  .unit-m-1-4,
  .m-1-4,
  .unit-m-3-4,
  .m-3-4 {
    display: inline-block;
    visibility: visible;
  }

  .unit-m-1-2[class*="-content-"],
  .m-1-2[class*="-content-"],
  .unit-m-1-3[class*="-content-"],
  .m-1-3[class*="-content-"],
  .unit-m-2-3[class*="-content-"],
  .m-2-3[class*="-content-"],
  .unit-m-1-4[class*="-content-"],
  .m-1-4[class*="-content-"],
  .unit-m-3-4[class*="-content-"],
  .m-3-4[class*="-content-"] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .unit-m-content-distribute,
  .m-content-distribute {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .unit-m-content-center,
  .unit-m-content-center-vertical {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: column wrap;
    -ms-flex-direction: column wrap;
    flex-flow: column wrap;
  }

  .unit-m-content-center-horizontal {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: row wrap;
    -ms-flex-direction: row wrap;
    flex-flow: row wrap;
  }

  .m-content-fill,
  .m-content-stretch {
    -ms-flex-grow: 2;
    -webkit-flex-grow: 2;
    flex-grow: 2;
    max-width: 100%; /* @bugfix: IE 10, 11 */
  }

  .m-content-shrink {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
  }

}

@media only screen and (min-width: 60em) {

  .unit-l-hidden,
  .l-0 {
    display: none;
    visibility: hidden;
  }

  .unit-l-centered {
    margin: 0 auto;
  }

  .unit-l-1,
  .l-1 {
    display: block;
    visibility: visible;
    width: 100%;
  }

  .unit-offset-l-0 {
    margin-left: 0;
  }

  .unit-push-l-0,
  .unit-pull-l-0 {
    left: 0;
  }

  .unit-l-1-2,
  .l-1-2 {
    width: 50%;
  }

  .unit-offset-l-1-2 {
    margin-left: 50%;
  }

  .unit-push-l-1-2 {
    left: 50%;
  }

  .unit-pull-l-1-2 {
    left: -50%;
  }

  .unit-l-1-3,
  .l-1-3 {
    width: 33.3333%;
  }

  .unit-offset-l-1-3 {
    margin-left: 33.3333%;
  }

  .unit-push-l-1-3 {
    left: 33.3333%;
  }

  .unit-pull-l-1-3 {
    left: -33.3333%;
  }

  .unit-l-2-3,
  .l-2-3 {
    width: 66.6667%;
  }

  .unit-offset-l-2-3 {
    margin-left: 66.6667%;
  }

  .unit-push-l-2-3 {
    left: 66.6667%;
  }

  .unit-pull-l-2-3 {
    left: -66.6667%;
  }

  .unit-l-1-4,
  .l-1-4 {
    width: 25%;
  }

  .unit-offset-l-1-4 {
    margin-left: 25%;
  }

  .unit-push-l-1-4 {
    left: 25%;
  }

  .unit-pull-l-1-4 {
    left: -25%;
  }

  .unit-l-3-4,
  .l-3-4 {
    width: 75%;
  }

  .unit-offset-l-3-4 {
    margin-left: 75%;
  }

  .unit-push-l-3-4 {
    left: 75%;
  }

  .unit-pull-l-3-4 {
    left: -75%;
  }

  .unit-l-1-5,
  .l-1-5 {
    width: 20%;
  }

  .unit-offset-l-1-5 {
    margin-left: 20%;
  }

  .unit-push-l-1-5 {
    left: 20%;
  }

  .unit-pull-l-1-5 {
    left: -20%;
  }

  .unit-l-2-5,
  .l-2-5 {
    width: 40%;
  }

  .unit-offset-l-2-5 {
    margin-left: 40%;
  }

  .unit-push-l-2-5 {
    left: 40%;
  }

  .unit-pull-l-2-5 {
    left: -40%;
  }

  .unit-l-3-5,
  .l-3-5 {
    width: 60%;
  }

  .unit-offset-l-3-5 {
    margin-left: 60%;
  }

  .unit-push-l-3-5 {
    left: 60%;
  }

  .unit-pull-l-3-5 {
    left: -60%;
  }

  .unit-l-4-5,
  .l-4-5 {
    width: 80%;
  }

  .unit-offset-l-4-5 {
    margin-left: 80%;
  }

  .unit-push-l-4-5 {
    left: 80%;
  }

  .unit-pull-l-4-5 {
    left: -80%;
  }

  .unit-l-1-6,
  .l-1-6 {
    width: 16.6667%;
  }

  .unit-offset-l-1-6 {
    margin-left: 16.6667%;
  }

  .unit-push-l-1-6 {
    left: 16.6667%;
  }

  .unit-pull-l-1-6 {
    left: -16.6667%;
  }

  .unit-l-5-6,
  .l-5-6 {
    width: 83.3333%;
  }

  .unit-offset-l-5-6 {
    margin-left: 83.3333%;
  }

  .unit-push-l-5-6 {
    left: 83.3333%;
  }

  .unit-pull-l-5-6 {
    left: -83.3333%;
  }

  .unit-l-1-2,
  .l-1-2,
  .unit-l-1-3,
  .l-1-3,
  .unit-l-2-3,
  .l-2-3,
  .unit-l-1-4,
  .l-1-4,
  .unit-l-3-4,
  .l-3-4,
  .unit-l-1-5,
  .l-1-5,
  .unit-l-2-5,
  .l-2-5,
  .unit-l-3-5,
  .l-3-5,
  .unit-l-4-5,
  .l-4-5,
  .unit-l-1-6,
  .l-1-6,
  .unit-l-5-6,
  .l-5-6 {
    display: inline-block;
    visibility: visible;
  }

  .unit-l-1-2[class*="-content-"],
  .l-1-2[class*="-content-"],
  .unit-l-1-3[class*="-content-"],
  .l-1-3[class*="-content-"],
  .unit-l-2-3[class*="-content-"],
  .l-2-3[class*="-content-"],
  .unit-l-1-4[class*="-content-"],
  .l-1-4[class*="-content-"],
  .unit-l-3-4[class*="-content-"],
  .l-3-4[class*="-content-"],
  .unit-l-1-5[class*="-content-"],
  .l-1-5[class*="-content-"],
  .unit-l-2-5[class*="-content-"],
  .l-2-5[class*="-content-"],
  .unit-l-3-5[class*="-content-"],
  .l-3-5[class*="-content-"],
  .unit-l-4-5[class*="-content-"],
  .l-4-5[class*="-content-"],
  .unit-l-1-6[class*="-content-"],
  .l-1-6[class*="-content-"],
  .unit-l-5-6[class*="-content-"],
  .l-5-6[class*="-content-"] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .unit-l-content-distribute,
  .l-content-distribute {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  .unit-l-content-center,
  .unit-l-content-center-vertical {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: column wrap;
    -ms-flex-direction: column wrap;
    flex-flow: column wrap;
  }

  .unit-l-content-center-horizontal {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-flex-direction: row wrap;
    -ms-flex-direction: row wrap;
    flex-flow: row wrap;
  }

  .l-content-fill,
  .l-content-stretch {
    -ms-flex-grow: 2;
    -webkit-flex-grow: 2;
    flex-grow: 2;
    max-width: 100%; /* @bugfix: IE 10, 11 */
  }

  .l-content-shrink {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
  }

}
