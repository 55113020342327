html {
  font-family: $font-primary;
  font-weight: 200;
}

body {
  background-color: #1a1a1a;
  color: #fff;
}

a {
  color: #fff;
  transition: all $transition;
}

a.no-link {
  pointer-events: none;
  cursor: default;
}

h2 {
  display: inline-block;
}

h2,
.line-highlight {
  letter-spacing: $tracking;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  &.absolute {
    position: absolute;
  }
  &::after,
  &::before {
    content: ' ';
    position: absolute;
    max-width: 3rem;
    width: 33.3333%;
    height: 3px;
    background-color: $line-color;
    transition:
      opacity .25s,
      left 1.5s cubic-bezier(.15,.88,.94,1.14),
      right 1.5s cubic-bezier(.15,.88,.94,1.14);
  }
  &::before {
    top: 0;
    right: -1rem;
  }
  &::after {
    left: -1rem;
    bottom: 0;
  }
}

.line-highlight {
  &::after,
  &::before {
    opacity: 0;
  }
  &:hover::before,
  &:focus::before {
    opacity: 1;
    right: calc(50% - .5rem);
  }
  &:hover::after,
  &:focus::after {
    opacity: 1;
    left: calc(50% - .5rem);
  }
}

button.line-highlight {
  background-color: transparent;
  border: 0;
  padding: 0;
}

.video-player-link:hover .line-highlight::before,
.video-player-link:focus .line-highlight::before {
  opacity: 1;
  right: calc(50% - .5rem);
}
.video-player-link:hover .line-highlight::after,
.video-player-link:focus .line-highlight::after {
  opacity: 1;
  left: calc(50% - .5rem);
}

.markdown p:last-child {
  margin-bottom: 0;
}
