.footer {
  .wrapper {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    text-align: center;
    @include min(38) {
      text-align: left;
      .icon-list {
        text-align: right;
      }
    }
    a {
      color: #fff;
    }
    .icon-list {
      li {
        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
      .icon-link {
        @include hov() {
          // opacity: .75;
          color: $line-color;
        }
      }
    }
  }
  .sub-footer {
    color: #999;
    a {
      color: #bbb;
    }
    small {
      @extend .micro;
    }
    @include min(38) {
      .unit:last-child {
        text-align: right;
      }
    }
  }
  .to-top {
    position: fixed;
    display: inline-block;
    padding: 1.3rem;
    background-color: $color-primary;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    @include hov() {
      transform: none;
    }
  }
}
