@mixin min($size) {
  @media only screen and (min-width: #{$size}em) {

    @content;

  }
}

@mixin max($size) {
  @media only screen and (max-width: #{$size}em) {

    @content;

  }
}

@mixin minmax($min, $max) {
  @media only screen and (min-width: #{$min}em) and (max-width: #{$max}em) {

    @content;

  }
}

@mixin bgBasic() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin hov() {
  &:hover, &:focus {

    @content;

  }
}
